<script setup lang="ts">
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as yup from 'yup'
import { IRequestForm } from '~/domain/requests'

// Composable
const { onClose } = useModalContext()
const { sendRequest } = useRequestsStore()

const { form, useField, onSubmit } = useForm<IRequestForm, any>({
  params: {
    submitMethod: (data) => sendRequest(data),
    onSuccess: () => {
      onClose()
    },
  },
  formParams: {
    initialValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
      content: '',
    },
    validationSchema: {
      name: yup.string().required(),
      company: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup
        .string()
        .required()
        .test('phone-valid', 'Номер телефона не валиден', (value) =>
          parsePhoneNumberFromString(value, 'RU')?.isValid()
        ),
      content: yup.string().required().max(3000),
    },
  },
})

const nameField = useField('name')
const companyField = useField('company')
const emailField = useField('email')
const phoneField = useField('phone')
const contentField = useField('content')

const isFormInvalid = computed(() => !form.meta.value.valid)
</script>

<template>
  <Modal class="flex w-full max-w-[575px] flex-col gap-6">
    <template #title>
      <div class="flex flex-col gap-4">
        <p class="text-headline font-bold md:text-headline-mobile">Проверка кандидата экспертом</p>
        <p>Оставьте заявку на живое техническое интервью от наших экспертов</p>
      </div>
    </template>
    <div class="flex w-full flex-col gap-6">
      <div class="flex w-full flex-col gap-5">
        <InputField
          :field="nameField"
          placeholder="Фамиля Имя Отчество"
          label="ФИО"
          class="w-full"
          required
        />
        <InputField
          :field="companyField"
          label="Компания"
          placeholder="FoxtailBox"
          class="w-full"
          required
        />
        <InputField
          :field="emailField"
          label="Электронная почта"
          placeholder="ivan@yandex.ru"
          required
          class="w-full"
        />
        <InputField
          :field="phoneField"
          label="Номер телефона"
          placeholder="+7 (996) 504 64 34"
          required
          class="w-full"
        />
        <label class="flex flex-col gap-2">
          <span>Опишите ваш запрос<span class="text-primary">*</span></span>
          <TextareaField :field="contentField" placeholder="Текст обращения..." />
        </label>
        <p><span class="text-primary">*</span>— обязательные поля</p>
      </div>
      <div class="flex gap-5">
        <Button class="w-full py-4" size="sm" :disabled="isFormInvalid" @click="onSubmit">
          Отправить отзыв
        </Button>
      </div>
    </div>
  </Modal>
</template>
