import { IRequestForm } from '~/domain/requests'

export const useRequestsStore = defineStore('requests', () => {
  const sendRequest = (form: IRequestForm) =>
    useApi('/requests', {
      method: 'POST',
      body: form,
    })

  return {
    sendRequest,
  }
})
